<template>
  <HomeSection>
    <MisListTable
      heading-tag="h2"
      :limit="5"
    />

    <div
      v-if="data?.length"
      class="mt-6 text-center"
    >
      <v-btn
        class="bg-ui-kit-bg-secondary text-primary"
        :to="{ name: 'mis' }"
      >
        Посмотреть все {{ data.length }} МИС
      </v-btn>
    </div>
  </HomeSection>
</template>

<script setup lang="ts">
import { ASYNC_DATA_KEYS } from '~/constants'
import type { MisStatisticItemDto } from '~/api/misStatistics'

const { data } = useNuxtData<MisStatisticItemDto[]>(ASYNC_DATA_KEYS.misList)
</script>
