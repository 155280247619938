<template>
  <v-carousel-item
    class="h-100"
    eager
  >
    <v-sheet
      class="px-4 py-6 px-md-8 pt-md-8 pb-md-0"
      :class="['h-100 rounded-4 bg-ui-kit-bg-secondary d-flex']"
    >
      <v-col
        class="pb-md-8"
        :class="['d-flex flex-column']"
      >
        <slot>
          <h3 class="text-h6">
            {{ title }}
          </h3>
          <IconList
            :class="$slots.afterContent ? 'my-6' : 'mt-6'"
            :items="items"
          />
          <div class="mt-auto">
            <slot name="afterContent" />
          </div>
        </slot>
      </v-col>
      <v-col class="ml-8 mt-auto d-none d-md-block">
        <v-img
          :src="imageSrc"
          :srcset="imageSrcset"
          :alt="imageAlt"
        />
      </v-col>
    </v-sheet>
  </v-carousel-item>
</template>

<script lang="ts">
import type { PropType } from 'vue'

export default defineComponent({
  name: 'WidgetSectionCarouselItem',
  props: {
    items: {
      type: Array as PropType<Array<{ icon: string, title: string, text?: string }>>,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      default: ''
    },
    imageSrcset: {
      type: String,
      default: ''
    },
    imageAlt: {
      type: String,
      default: ''
    }
  }
})
</script>
