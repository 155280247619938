<template>
  <v-sheet
    class="flex-column flex-md-row"
    :class="['mt-6 pa-6 d-flex align-center bordered-sheet']"
  >
    <div class="mr-6">
      <h3 class="text-h5">
        {{ title }}
      </h3>
      <p
        class="mt-4 mt-md-0"
        :class="['text-body-1 text-ui-kit-text-secondary']"
      >
        {{ text }}
      </p>
    </div>
    <v-btn
      class="mt-4 mt-md-0 ml-md-auto w-100 w-md-auto"
      :href="btnHref"
      @click="$emit('click:action')"
    >
      {{ btnText }}
    </v-btn>
  </v-sheet>
</template>

<script lang="ts">
export default defineComponent({
  name: 'BorderedSheet',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    btnHref: {
      type: String,
      default: ''
    }
  },
  emits: ['click:action']
})
</script>

<style scoped lang="scss">
.bordered-sheet {
  border-radius: 16px !important;
  border: 2px solid $t-ui-kit-bg-primary !important;
}
</style>
