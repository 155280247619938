<template>
  <div class="home-page">
    <MainSection
      class="mt-10 mt-md-14"
      :class="['home-page__section home-page__section_main']"
      :statistics="statistics!"
      @click:open-form="handleShowContactForm(ContactFormRequest.ConnectMedflex)"
    />
    <InsuranceCompaniesSection
      :id="SectionId.ForInsuranceService"
      class="home-page__section"
    />
    <ClinicFeaturesSection
      :id="SectionId.ForClinic"
      class="home-page__section"
    />

    <PartnersSection
      :id="SectionId.Partners"
      class="home-page__section"
    />
    <WidgetSection
      :id="SectionId.Widgets"
      class="home-page__section"
      @click:open-form="handleShowContactForm(ContactFormRequest.InstallingWidgets)"
    />

    <MisListSection
      :id="SectionId.MisList"
      class="home-page__section"
    />

    <ClinicsSection
      class="home-page__section"
      :data-anchor-id="SectionId.Clinics"
      :clinics-count="statistics!.lpusCount"
      @click:open-form="handleShowContactForm(ContactFormRequest.ConnectMedflex)"
    />

    <ClientOnly>
      <ContactFormDialog
        ref="contactFormDialog"
        @send="handleShowContactFormMessage(ContactFormDialogMessageType.Success)"
        @error:limit="handleShowContactFormMessage(ContactFormDialogMessageType.Error)"
      />
      <ContactFormDialogMessage ref="contactFormDialogMessage" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { ContactFormDialogMessageType, ContactFormRequest, SectionId } from '~/enums'
import { homeApi } from '~/api'
import { ASYNC_DATA_KEYS } from '~/constants'
import { getMisStatistics } from '~/api/misStatistics'

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://medflex.ru'
    }
  ]
})

const sections = ref<HTMLElement[]>([])
const contactFormDialog = ref<{ show:(type: ContactFormRequest) => void } | null>(null)
const contactFormDialogMessage = ref<{ show:(type: ContactFormDialogMessageType) => void } | null>(null)

const { data: statistics } = await useAsyncData(ASYNC_DATA_KEYS.statics, () => homeApi.getStatistics())
await useAsyncData(ASYNC_DATA_KEYS.misList, () => getMisStatistics(), {
  default() {
    return []
  }
})

if (!statistics.value) {
  throw createError({
    statusCode: 500
  })
}

useSeoMeta({
  description: `${statistics.value!.lpusCount} - столько частных клиник и стоматологий используют сервис МедФлекс для безопасного обмена данными с онлайн-сервисами и страховыми компаниями, а также для организации онлайн-записи пациентов`
})

const router = useRouter()

onMounted(() => {
  sections.value = Array.from(document.querySelectorAll('.home-page__section'))
  const scroll = useScroll(document)

  watch(() => scroll.y.value, (val) => {
    if (!sections.value.length) {
      return
    }

    // NOTE: На высоких экранах не детектится первая секция => обнуление якоря если скролл близок к нулю
    if (scroll.y.value < 50) {
      router.replace({
        hash: '',
        params: {
          _disableAutoScrollToHash: 'true'
        }
      })

      return
    }

    const scrollToCenterWindow = val + window.innerHeight * 0.4
    sections.value.forEach(section => checkIntersectSection(section, scrollToCenterWindow))
  })
})

function checkIntersectSection(section: HTMLElement, scrollToCenterWindow: number) {
  const offsetTop = section.offsetTop
  const height = section.offsetHeight

  if (offsetTop < scrollToCenterWindow && scrollToCenterWindow < offsetTop + height) {
    const sectionId = section.id || section.dataset.anchorId || ''

    router.replace({
      hash: sectionId ? '#' + sectionId : '',
      params: {
        _disableAutoScrollToHash: 'true'
      }
    })
  }
}

function handleShowContactForm(type: ContactFormRequest) {
  contactFormDialog.value?.show(type)
}

function handleShowContactFormMessage(type: ContactFormDialogMessageType) {
  contactFormDialogMessage.value?.show(type)
}
</script>

<style scoped lang="scss">
.home-page {
  @media (max-width: $desktop-breakpoint) {
    #{&}__section {
      margin-bottom: 80px;

      &_main {
        margin-bottom: 48px;
      }
    }
  }

  &__section {
    margin-bottom: 120px;

    &_main {
      margin-bottom: 112px;
    }
  }
}
</style>
