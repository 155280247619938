<template>
  <div class="py-4 d-flex align-center">
    <div class="mr-4 pa-2 bg-primary rounded-circle align-self-start line-height-0">
      <v-icon
        size="20"
        :icon="icon"
      />
    </div>
    <div>
      <p
        class="text-subtitle-1"
        v-html="title"
      />
      <p
        :class="['text-body-1', { 'mt-1': title }]"
        v-html="text"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'IconListItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
})
</script>
