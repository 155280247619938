<template>
  <HomeSection
    title="Наши партнёры"
    subtitle="С этими сервисами уже всё настроено — вам нужно только включить интеграцию"
  >
    <div class="d-flex flex-wrap justify-center gc-4 gc-md-3">
      <v-img
        v-for="integration in data?.integrations"
        :key="integration.title"
        class="flex-grow-0 partner-section-img"
        :src="integration.logoUrl"
        :alt="integration.title"
      />
    </div>
    <BorderedSheet
      title="Интегрируемся с другими сервисами"
      text="С помощью API МедФлекс. Нужно только передать сервису ключ доступа (токен)."
      btn-text="API для интеграции"
      btn-href="https://api.medflex.ru/"
    />
  </HomeSection>
</template>

<script setup lang="ts">
import { ASYNC_DATA_KEYS } from '~/constants'

const { data } = useNuxtData(ASYNC_DATA_KEYS.statics)
</script>

<style lang="scss">
.partner-section-img {
  width: 156px;
  height: 69px;

  @media (min-width: $desktop-breakpoint) {
    width: 214px;
    height: 88px;
  }
}
</style>
