<template>
  <HomeSection
    title="Виджеты МедФлекс"
    subtitle="Сделайте ваш сайт эффективным инструментом для привлечения и удержания пациентов"
  >
    <v-chip-group
      v-model="activeChip"
      class="mb-6 d-none d-md-block"
      mandatory
    >
      <div class="mx-auto">
        <v-chip
          v-for="chip in chips"
          :key="chip.value"
          :value="chip.value"
          :text="chip.name"
        />
      </div>
    </v-chip-group>

    <div class="widget-section-carousel">
      <v-carousel
        v-model="activeChip"
        height="100%"
        :show-arrows="false"
        hide-delimiters
      >
        <WidgetSectionCarouselItem
          title="Пациенты смогут записаться к врачам онлайн на удобное время. Запись попадёт сразу в МИС"
          image-src="images/features/widgets/online_appointment.png"
          image-srcset="images/features/widgets/online_appointment.png 1x,
          images/features/widgets/online_appointment_3x.png 3x"
          image-alt="Виджет онлайн-записи"
          :value="Widgets.OnlineAppointment"
          :items="widgetFeatures[Widgets.OnlineAppointment]"
        />

        <WidgetSectionCarouselItem
          title="Установите на сайте единый личный кабинет пациента — МедТочку"
          image-src="images/features/widgets/patient_medcard.png"
          image-srcset="images/features/widgets/patient_medcard.png 1x,
          images/features/widgets/patient_medcard_3x.png 3x"
          image-alt="Личный кабинет пациента"
          :value="Widgets.PatientMedcard"
          :items="widgetFeatures[Widgets.PatientMedcard]"
        >
          <template #afterContent>
            <v-btn
              variant="text"
              href="https://medtochka.ru/"
              target="_blank"
            >
              Узнать больше о МедТочке
            </v-btn>
          </template>
        </WidgetSectionCarouselItem>

        <WidgetSectionCarouselItem
          title="Больше не нужно загружать и обновлять цены на сайте вручную.
        Виджет показывает то, что указано в вашей МИС"
          image-src="images/features/widgets/price_list.png"
          image-srcset="images/features/widgets/price_list.png 1x, images/features/widgets/price_list_3x.png 3x"
          image-alt="Виджет прайс-листа"
          :value="Widgets.PriceList"
          :items="widgetFeatures[Widgets.PriceList]"
        />
      </v-carousel>
    </div>

    <div class="mt-4 dots d-flex d-md-none">
      <div
        v-for="chip in chips"
        :key="chip.value"
        :class="['dots__item cursor-pointer', {'dots__item_active': activeChip === chip.value}]"
        @click="activeChip = chip.value"
      />
    </div>

    <BorderedSheet
      title="Установим виджеты на ваш сайт бесплатно"
      text="Для этого оставьте заявку или свяжитесь с персональным менеджером МедРокет"
      btn-text="Оставить заявку"
      @click:action="$emit('click:open-form')"
    />
  </HomeSection>
</template>

<script lang="ts">
enum Widgets {
  OnlineAppointment = 'ONLINE_APPOINTMENT',
  PatientMedcard = 'PATIENT_MEDCARD',
  PriceList = 'PRICE_LIST'
}

export default defineComponent({
  name: 'WidgetSection',
  emits: ['click:open-form'],
  setup() {
    const activeChip = ref<Widgets | null>(null)

    onMounted(() => {
      activeChip.value = Widgets.OnlineAppointment
    })

    const chips = [
      {
        value: Widgets.OnlineAppointment,
        name: 'Виджет онлайн-записи'
      },
      {
        value: Widgets.PatientMedcard,
        name: 'Личный кабинет пациента'
      },
      {
        value: Widgets.PriceList,
        name: 'Виджет прайс-листа'
      }
    ]

    const widgetFeatures = {
      [Widgets.OnlineAppointment]: [
        {
          icon: 'ui-icon-globe',
          title: 'Виджет записи на 2ГИС, Яндекс, ВК ',
          text: 'Просто разместите ссылку на виджет на этих площадках и получайте поток пациентов.'
        },
        {
          icon: 'ui-icon-home-MedLock',
          title: 'Виджет записи  на сайте клиники',
          text: 'Мы установим кнопку записи на ваш сайт, которая всегда будет видна пациенту.'
        }
      ],
      [Widgets.PatientMedcard]: [
        {
          icon: 'ui-icon-heart',
          title: 'Ваша клиника сохранится в разделе «Мои клиники»',
          text: 'Если пациент перейдёт в личный кабинет с вашего сайта.'
        },
        {
          icon: 'ui-icon-bell',
          title: 'МедТочка будет заранее напоминать о приёмах',
          text: 'И повысит посещаемость.'
        },
        {
          icon: 'ui-icon-med-book',
          title: 'У ваших пациентов будет электронная медкарта',
          text: 'Врачи смогут посмотреть все документы на компьютере или на телефоне. Если пользуетесь МедЛоком, сможете отправлять заключения и результаты анализов прямо в медкарту пациента.'
        }
      ],
      [Widgets.PriceList]: [
        {
          icon: 'ui-icon-search',
          title: 'Удобный поиск по списку услуг для посетителей вашего сайта',
          text: 'Пациенты смогут быстро найти то, что их интересует, и не будут загружать колл-центр, чтобы «просто спросить».'
        },
        {
          icon: 'ui-icon-settings',
          title: 'Гибкие настройки',
          text: 'Если вы не хотите показывать какие-то услуги из МИС на сайте, можете их просто скрыть из виджета.'
        },
        {
          icon: 'ui-icon-gerb',
          title: 'Всё как требует Минздрав',
          text: 'На сайте клиники обязательно должен быть список услуг с актуальными ценами. Виджет решает эту задачу.'
        }
      ]
    }

    return {
      Widgets,
      activeChip,
      chips,
      widgetFeatures
    }
  }
})
</script>

<style lang="scss">
.widget-section-carousel {
  height: 712px;

  @media (min-width: 600px) {
    height: 460px;
  }

  @media (min-width: $desktop-breakpoint) {
    height: 632px;
  }

  @media (min-width: 1040px) {
    height: 516px;
  }
}

.dots {
  display: flex;
  justify-content: center;
  gap: 8px;

  &__item {
    border-radius: 50%;
    background-color: $t-ui-kit-bg-gray-80;
    width: 8px;
    height: 8px;

    &_active {
      background-color: $t-primary;
    }
  }
}
</style>
