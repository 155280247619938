<template>
  <HomeSection
    title="Для страховых компаний и онлайн-сервисов"
    subtitle="Быстрый доступ к расписанию врачей и ценам из МИС клиник"
    class="bg-ui-kit-bg-gray-40 feature-card__container"
  >
    <div class="d-flex flex-wrap justify-center ga-6">
      <v-sheet
        v-for="feature in features"
        :key="feature.title"
        width="360"
        class="pt-4 pb-8 px-4 rounded-4"
      >
        <v-sheet class="pl-4 pr-2 py-3 bg-ui-kit-bg-secondary d-flex rounded-2 feature-card">
          <h3 class="pt-3 feature-card__title">
            {{ feature.title }}
          </h3>
          <v-img
            width="96"
            height="96"
            :src="feature.image"
            :alt="feature.title"
          />
        </v-sheet>
        <p class="mt-4 text-body-1">
          {{ feature.text }}
        </p>
      </v-sheet>
    </div>
  </HomeSection>
</template>

<script lang="ts">
export default defineComponent({
  name: 'InsuranceCompaniesSection',
  setup() {
    const features = [
      {
        title: 'Доступ к данным клиник без сложных настроек',
        text: 'Не тратьте время на настройку интеграций с МИС – работайте со множеством клиник уже сейчас',
        image: 'images/features/insuranceCompanies/easy_settings.svg'
      },
      {
        title: 'Запись пациентов сразу в МИС клиники',
        text: 'Записывайте пациентов на приём без созвонов и согласований с клиникой',
        image: 'images/features/insuranceCompanies/appointment_to_mis.svg'
      },
      {
        title: 'Высокий уровень вашего сервиса',
        text: 'Предоставьте пациентам удобный сервис для записи к врачам',
        image: 'images/features/insuranceCompanies/top_level_service.svg'
      }
    ]

    return {
      features
    }
  }
})
</script>

<style lang="scss">
.feature-card {
  &__container {
    padding: 32px 0;

    @media (min-width: $desktop-breakpoint) {
      padding: 80px 0;
    }
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0.15px;
  }
}
</style>
