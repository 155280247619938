<template>
  <div>
    <IconListItem
      v-for="item in items"
      :key="item.title"
      :icon="item.icon"
      :title="item.title"
      :text="item.text"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'

export default defineComponent({
  name: 'IconList',
  props: {
    items: {
      type: Array as PropType<Array<{ icon: string, title: string, text?: string }>>,
      default: () => []
    }
  }
})
</script>
